import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Formik } from "formik";
import { Avatar, Box, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import moment from "moment";
import { useDispatch } from "react-redux";
import { updateSnackBar, useAppSelector, selectAuth } from "@redux";

import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
import { AppTopBar, AppSelect, AppModal, AppAlert, AppIconButton, AppButton, CircularLoader, AppDateRangePicker } from "..";
import { StyledNewOT, OTApprovalProcess } from ".";
import { appRouteEnums, OVERTIME_TYPE_VALUE } from "@enums";
import { initOTAllType, linkImage, OTFormBereavementSchema, OTFormDefault, OTFormHalfDaySchema, OTFormHolidayWorkSchema, OTFormNightToDaySchema, OTFormOvertimeSchema, OTFormVacationSchema, overtimeNewTitle, useConstructionSites, } from "@utils";
import { ICreateNewOTParams, IUpdateOTParams, overtimeApi, timesheetApi } from "@api";
import hot_yellow_frame from "@assets/Icons/hot_yellow_frame.svg";
import clsx from "clsx";
import dayjs from "dayjs";
import OTNewVacation from "./OTNewVacation";
import OTNewHoliday from "./OTNewHoliday";
import OTNewHalfDay from "./OTNewHalfDay";
import OTNewNightShift from "./OTNewNightShift";
import OTNewOvertime from "./OTNewOvertime";
import OTNewDayToNight from "./OTNewDayToNight";
import OTNewSpecialLeave from "./OTNewSpecialLeave";
import OTNewBereavement from "./OTNewBereavement";
import OTNewAbsense from "./OTNewAbsense";
import OTNewTardiness from "./OTNewTardiness";
import OTNewEarlyLeave from "./OTNewEarlyLeave";
import OTNewNightToDay from "./OTNewNightToDay";

const optionOthers = [
  {
    name: "その他",
    id: 0,
  },
];
export const NewOT = () => {
  const { id } = useParams<{ id: any }>();
  const urlParams = new URLSearchParams(window.location.search);
  const date = urlParams.get("date");
  // redux state
  const userInfo = useAppSelector(selectAuth).userInfo;
  //component states
  const [dateRate, setDateRate] = React.useState<any>({
    // startDate: new Date(),
    // endDate: new Date(),
    startDate: date ? new Date(date) : new Date(),
    endDate: date ? new Date(date) : new Date(),
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
  const [optionSiteConstructions, setOptionSiteConstructions] = React.useState<any>();
  const [dataDetail, setDataDetail] = React.useState<any>(null);
  const [listReasons, setListReasons] = React.useState<any>();
  const [optionGroups, setOptionGroup] = React.useState<any>();
  const [checkTypeTextOT, setCheckTypeTextOT] = React.useState<any>();
  const [isSectionChief, setIsSectionChief] = React.useState<boolean>();
  const [valuesTitle, setValuesTitle] = React.useState<any>();
  const [updateTypeOt, setUpdateTypeOt] = React.useState<any>(false);
  const [changeToOfficial, setChangeToOfficial] = React.useState(false);

  // hooks
  const history = useHistory();
  const dispatch = useDispatch();
  const { updateConstructionHistory, sortConstructionSites } = useConstructionSites();

  const [initialValuesPackage, setInitialValuesPackage] = React.useState(initOTAllType);
  const enableCheckTypeOfOverTime = history.location.pathname.includes("edit");
  React.useEffect(() => {
    fetchListReasons();
    return () => {
      // clean up
    };
  }, []);
  React.useEffect(() => {
    fetchListConstructions();
    return () => {
      // do something with cleanup
    };
  }, []);
  React.useEffect(() => {
    fetchListConstractors();
    return () => {
      // do something with cleanup
    };
  }, []);
  React.useEffect(() => {
    fetchListGroupsByUser();
    return () => {
      // do something with cleanup
    };
  }, []);
  React.useEffect(() => {
    fetchRoleUser();
    return () => {
      // do something with cleanup
    };
  }, []);
  React.useEffect(() => {
    if (id) {
      fetchViewDetail(id);
    }
    return () => {
      // do something with cleanup
    };
  }, [id, updateTypeOt]);
  // fetch api group by user
  const fetchListGroupsByUser = async () => {
    if (!id) {
      try {
        const result = await timesheetApi.listGroupsByUser(userInfo?.id);
        setOptionGroup(result.data.data);
      } catch (err) { }
    }
  };
  const fetchRoleUser = async () => {
    try {
      const res = await overtimeApi.getRoleOfUser(userInfo?.id);
      setIsSectionChief(res?.data?.data?.isSectionChief);
    } catch (error) { }
  };
  // fetch api view detail OT
  const fetchViewDetail = async (id: any) => {
    setLoading(true);
    try {
      const responseConstructions = await timesheetApi.getListConstructions();
      setOptionSiteConstructions(sortConstructionSites(responseConstructions.data.data));
      const resultGroups = await timesheetApi.listGroupsByUser(userInfo?.id);
      setOptionGroup(resultGroups.data.data);
      const result = await overtimeApi.getListReasons();
      setListReasons([...result.data.data, ...optionOthers]);
      const res = await overtimeApi.viewOTDetail({
        id,
      });
      if (res.data.data.overtimeData?.employee?.id !== userInfo?.id) {
        history.push(`${appRouteEnums.PATH_OVERTIME}`);
      }
      setDataDetail(res.data.data);
      setLoading(false);
      setDateRate({
        startDate: dayjs(res.data.data?.overtimeData?.from, "YYYY-MM-DD").toDate(),
        endDate: dayjs(res.data.data?.overtimeData?.to, "YYYY-MM-DD").toDate(),
      });
      setInitialValuesPackage({
        title: res.data.data.overtimeData.title || "",
        reasonType: res.data.data?.overtimeData?.reason?.id || "0",
        phoneNumber: res.data.data?.overtimeData?.phone_number?.toString() || "",
        reasonContent: res.data.data?.overtimeData?.note || "",
        memo: res.data.data?.overtimeData?.memo || "",
        start_time: res?.data?.data?.overtimeData?.start_at || "",
        stop_time: res?.data?.data?.overtimeData?.stop_at || "",
        type_of_overtime: res.data.data?.overtimeData?.type_of_overtime === 0 ? "0" : "1",
        break_time: res.data.data.overtimeData.break_time || "",
        construction: res.data.data.overtimeData.constructionId?.id || "",
        subContractor:
          res.data.data.overtimeData.contractor?.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          }) || "",
        detail: res.data.data?.overtimeData?.detail || "",
        work_detail: res.data.data.overtimeData.work_detail || "",
        work_time: res.data.data.overtimeData.work_time || "",
        compensatory_leave: res.data.data.overtimeData.compensatory_leave || 0,
        start_at_extra: res?.data?.data?.overtimeData?.start_at_extra || "",
        stop_at_extra: res?.data?.data?.overtimeData?.stop_at_extra || "",
        break_time_extra: res?.data?.data?.overtimeData?.break_time_extra || "",
        work_time_extra: res?.data?.data?.overtimeData?.work_time_extra || "",
        construction_id_extra: res?.data?.data?.overtimeData?.constructionIdExtra?.id || "",
        work_detail_extra: res?.data?.data?.overtimeData?.work_detail_extra || "",
        contractor_id_extra:
          res?.data?.data?.overtimeData?.contractorextra?.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          }) || "",
        memo_extra: res?.data?.data?.overtimeData?.memo_extra || "",
        family_relationship: res?.data?.data?.overtimeData?.family_relationship || "",
        family_living: `${res?.data?.data?.overtimeData?.family_living}` || "",
      });

      setCheckTypeTextOT(res.data.data?.overtimeData?.type_of_overtime);
    } catch (err: any) {
      setLoading(false);
      dispatch(
        updateSnackBar({
          messageType: "error",
          message: err.response?.data?.message,
        })
      );
      history.push(appRouteEnums.PATH_OVERTIME);
    }
  };
  // fetch api list work type
  const fetchListReasons = async () => {
    if (!id) {
      try {
        const result = await overtimeApi.getListReasons();
        setListReasons([...result.data.data, ...optionOthers]);
      } catch (err) { }
    }
  };
  // fetch api create new OT
  const fetchApiCreateNewOT = async (values: ICreateNewOTParams, resetForm: any) => {
    setLoading(true);
    try {
      const res = await overtimeApi.createNewOTVacation(values);
      setLoading(false);
      setMessage(res.data.message);
      setIsSuccess(true);
      setOpenModal(true);
      resetForm();
    } catch (err: any) {
      setLoading(false);
      setIsSuccess(false);
      dispatch(
        updateSnackBar({
          messageType: "error",
          message: err.response?.data?.message,
        })
      );
    }
  };

  // fetch api update OT
  const fetchUpdateOT = async (values: IUpdateOTParams) => {
    setLoading(true);

    try {

      // const responseGroups = await timesheetApi.listGroupsByUser(userInfo?.id);
      // setGroups(responseGroups.data.data);
      const res = await overtimeApi.updateOT(values);
      setChangeToOfficial(false);
      setLoading(false);
      setMessage(res.data.message);
      setOpenModal(true);
      setIsSuccess(true);
    } catch (err: any) {
      setChangeToOfficial(false);
      setLoading(false);
      setIsSuccess(false);
      dispatch(
        updateSnackBar({
          messageType: "error",
          message: err.response?.data?.message,
        })
      );
    }

  };
  // fetch api
  const fetchListConstructions = async () => {
    if (!id) {
      try {
        const result = await timesheetApi.getListConstructions();
        setOptionSiteConstructions(sortConstructionSites(result.data.data));
      } catch (err) { }
    }
  };
  // events
  const handleChangeDateRange = (e) => {
    setDateRate({ startDate: e.startDate, endDate: e.endDate });
  };

  const handleOnSubmit = (values: any, resetForm: any) => {
    if (id) {

      fetchUpdateOT({
        ...values,
        id: id,
      });

    } else {
      fetchApiCreateNewOT(values, resetForm);
    }
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    isSuccess && history.push(appRouteEnums.PATH_OVERTIME);
  };

  const fetchDeleteOverTimes = async () => {
    setLoading(true);
    try {
      const result = await overtimeApi.deleteOverTimes(id);
      setMessage(result.data.message);
      setIsSuccess(true);
      dispatch(
        updateSnackBar({
          messageType: "success",
          message: result.data.message,
        })
      );
      setLoading(false);
      if (id) {
        history.push(appRouteEnums.PATH_OVERTIME);
      }
    } catch (error: any) {
      setLoading(false);
      dispatch(
        updateSnackBar({
          messageType: "error",
          message: error.response?.data?.message,
        })
      );
    }
  };
  const handleDelete = () => {
    fetchDeleteOverTimes();
  };

  const submitChangeTypeOT = async (data) => {
    setLoading(true);
    try {
      const res = await overtimeApi.changeOverTimesType({
        overtime_id: data,
        // overtime_id: 1,
      });

      setMessage(res.data.message);
      // setOpenPopupNoti(true);
      setUpdateTypeOt(true);
      // setTypeOT(true);
      setLoading(false);
      history.push(appRouteEnums.PATH_OVERTIME);
      // window.location.reload();
    } catch (err: any) {
      setLoading(false);
      dispatch(
        updateSnackBar({
          messageType: "error",
          message: err.response?.data?.message,
        })
      );
    }
  };

  const [optionConstractors, setOptionConstractors] = React.useState<any>();

  const fetchListConstractors = async () => {
    // if (!id) {
    try {
      const result = await timesheetApi.getListContractors();
      setOptionConstractors(result.data.data);
      if (result.data.data.suggestedContractors?.length) {
        setInitialValuesPackage({
          ...initialValuesPackage,
          subContractor: result.data.data.suggestedContractors?.map((item) => {
            return {
              label: item.name,
              value: item.id.toString(),
            };
          }),
        });
      } else {
        setOptionConstractors(result.data.data);
      }
    } catch (err) { }
    // }
  };

  const onChangeConstractors = (e, values, setFieldValue) => {
    const listConstractors = e.map((e, i) => {
      return {
        contractor_id: e.value.toString(),
        ...values.subContractor[i],
      };
    });
    setFieldValue("subContractor", listConstractors);
  };

  const onChangeConstractorsExtra = (e, values, setFieldValue) => {
    const listConstractors = e.map((e, i) => {
      return {
        contractor_id: e.value.toString(),
        ...values.subContractor[i],
      };
    });
    setFieldValue("contractor_id_extra", listConstractors);
  };

  const onChangeConstruction = (e, setFieldValue) => {
    setFieldValue("construction", e.value.toString());
  };

  const onChangeConstructionExtra = (e, setFieldValue) => {
    setFieldValue("construction_id_extra", e.value.toString());
  };

  let schema;

  switch (valuesTitle) {
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_VACATION:
      schema = OTFormVacationSchema;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_SPECIAL_LEAVE:
      schema = OTFormVacationSchema;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_OVERTIME:
      schema = OTFormOvertimeSchema;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_BEREAVEMENT:
      schema = OTFormBereavementSchema;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_HALFDAY:
      schema = OTFormHalfDaySchema;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_HOLIDAY:
      schema = OTFormHolidayWorkSchema;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_ABSENSE:
      schema = OTFormVacationSchema;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_EARLY_LEAVE:
      schema = OTFormVacationSchema;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_TARDINESS:
      schema = OTFormVacationSchema;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_NIGHTSHIFT:
      schema = OTFormOvertimeSchema;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_DAYTONIGHT:
      schema = OTFormNightToDaySchema;
      break;
    case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_NIGHTTODAY:
      schema = OTFormNightToDaySchema;
      break;
    default:
      schema = OTFormDefault;
      break;
  }

  return (
    <StyledNewOT>
      <AppTopBar />
      <CircularLoader loading={loading} />
      <AppModal open={openModal} handleClose={handleCloseModal}>
        <AppAlert icon={<img src={hot_yellow_frame} alt="icon" />} text={message} handleClose={handleCloseModal} />
      </AppModal>
      <div className="newOT">
        <div className="newOT__top">
          <p className="newOT__top-icon" onClick={() => history.push(appRouteEnums.PATH_OVERTIME)}>
            <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
          </p>
          <p className="newOT__top-title">私の申請</p>
        </div>
        <Formik
          initialValues={initialValuesPackage}
          enableReinitialize
          onSubmit={(values, { resetForm }) => {
            updateConstructionHistory(values?.construction);

            switch (values?.title) {
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_VACATION:
                handleOnSubmit(
                  {
                    from: moment(dateRate.startDate).format("YYYY-MM-DD"),
                    to: moment(dateRate.endDate).format("YYYY-MM-DD"),
                    start_at: values.start_time,
                    stop_at: values.stop_time,
                    overtime_reason_id: values.reasonType,
                    note: values.reasonContent ? values.reasonContent : null,
                    title: values.title,
                    memo: values.memo,
                    phone_number: values.phoneNumber,
                    type_of_overtime: changeToOfficial ? 1 : values.type_of_overtime,
                  },
                  resetForm
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_SPECIAL_LEAVE:
                handleOnSubmit(
                  {
                    from: moment(dateRate.startDate).format("YYYY-MM-DD"),
                    to: moment(dateRate.endDate).format("YYYY-MM-DD"),
                    start_at: values.start_time,
                    stop_at: values.stop_time,
                    overtime_reason_id: values.reasonType,
                    note: values.reasonContent ? values.reasonContent : null,
                    title: values.title,
                    memo: values.memo,
                    phone_number: values.phoneNumber,
                    type_of_overtime: changeToOfficial ? 1 : values.type_of_overtime,
                  },
                  resetForm
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_OVERTIME:
                handleOnSubmit(
                  {
                    from: moment(dateRate.startDate).format("YYYY-MM-DD"),
                    to: moment(dateRate.endDate).format("YYYY-MM-DD"),
                    start_at: values.start_time,
                    stop_at: values.stop_time,
                    // overtime_reason_id: values.reasonType,
                    work_detail: values.work_detail,
                    title: values.title,
                    memo: values.memo,
                    type_of_overtime: changeToOfficial ? 1 : values.type_of_overtime,
                    break_time: values.break_time,
                    work_time: values.work_time,
                    construction_id: values.construction,
                    contractor_id: values.subContractor?.map((item: any) => Number(item?.value)),
                  },
                  resetForm
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_BEREAVEMENT:
                handleOnSubmit(
                  {
                    from: moment(dateRate.startDate).format("YYYY-MM-DD"),
                    to: moment(dateRate.endDate).format("YYYY-MM-DD"),
                    title: values.title,
                    memo: values.memo,
                    phone_number: values.phoneNumber,
                    type_of_overtime: changeToOfficial ? 1 : +values.type_of_overtime,
                    family_relationship: values.family_relationship,
                    family_living: +values.family_living,
                  },
                  resetForm
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_HOLIDAY:
                handleOnSubmit(
                  {
                    from: moment(dateRate.startDate).format("YYYY-MM-DD"),
                    to: moment(dateRate.endDate).format("YYYY-MM-DD"),
                    start_at: values.start_time,
                    stop_at: values.stop_time,
                    // overtime_reason_id: values.reasonType,
                    work_detail: values.work_detail,
                    title: values.title,
                    memo: values.memo,
                    type_of_overtime: changeToOfficial ? 1 : values.type_of_overtime,
                    break_time: values.break_time,
                    work_time: values.work_time,
                    construction_id: values.construction,
                    contractor_id: values.subContractor?.map((item: any) => Number(item?.value)),
                    detail: values.detail,
                    compensatory_leave: Number(values.compensatory_leave),
                  },
                  resetForm
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_ABSENSE:
                handleOnSubmit(
                  {
                    from: moment(dateRate.startDate).format("YYYY-MM-DD"),
                    to: moment(dateRate.endDate).format("YYYY-MM-DD"),
                    start_at: values.start_time,
                    stop_at: values.stop_time,
                    overtime_reason_id: values.reasonType,
                    note: values.reasonContent ? values.reasonContent : null,
                    title: values.title,
                    memo: values.memo,
                    phone_number: values.phoneNumber,
                    type_of_overtime: changeToOfficial ? 1 : values.type_of_overtime,
                  },
                  resetForm
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_EARLY_LEAVE:
                handleOnSubmit(
                  {
                    from: moment(dateRate.startDate).format("YYYY-MM-DD"),
                    to: moment(dateRate.endDate).format("YYYY-MM-DD"),
                    start_at: values.start_time,
                    stop_at: values.stop_time,
                    overtime_reason_id: values.reasonType,
                    note: values.reasonContent ? values.reasonContent : null,
                    title: values.title,
                    memo: values.memo,
                    phone_number: values.phoneNumber,
                    type_of_overtime: changeToOfficial ? 1 : values.type_of_overtime,
                  },
                  resetForm
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_TARDINESS:
                handleOnSubmit(
                  {
                    from: moment(dateRate.startDate).format("YYYY-MM-DD"),
                    to: moment(dateRate.endDate).format("YYYY-MM-DD"),
                    start_at: values.start_time,
                    stop_at: values.stop_time,
                    overtime_reason_id: values.reasonType,
                    note: values.reasonContent ? values.reasonContent : null,
                    title: values.title,
                    memo: values.memo,
                    phone_number: values.phoneNumber,
                    type_of_overtime: changeToOfficial ? 1 : values.type_of_overtime,
                  },
                  resetForm
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_NIGHTSHIFT:
                handleOnSubmit(
                  {
                    from: moment(dateRate.startDate).format("YYYY-MM-DD"),
                    to: moment(dateRate.endDate).format("YYYY-MM-DD"),
                    start_at: values.start_time,
                    stop_at: values.stop_time,
                    // overtime_reason_id: values.reasonType,
                    work_detail: values.work_detail,
                    title: values.title,
                    memo: values.memo,
                    type_of_overtime: changeToOfficial ? 1 : values.type_of_overtime,
                    break_time: values.break_time,
                    work_time: values.work_time,
                    construction_id: values.construction,
                    contractor_id: values.subContractor?.map((item: any) => Number(item?.value)),
                  },
                  resetForm
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_DAYTONIGHT:
                handleOnSubmit(
                  {
                    from: moment(dateRate.startDate).format("YYYY-MM-DD"),
                    to: moment(dateRate.endDate).format("YYYY-MM-DD"),
                    start_at: values.start_time,
                    stop_at: values.stop_time,
                    // overtime_reason_id: values.reasonType,
                    work_detail: values.work_detail,
                    title: values.title,
                    memo: values.memo,
                    type_of_overtime: changeToOfficial ? 1 : values.type_of_overtime,
                    break_time: values.break_time,
                    work_time: values.work_time,
                    construction_id: values.construction,
                    contractor_id: values.subContractor?.map((item: any) => Number(item?.value)),
                    start_at_extra: values.start_at_extra,
                    stop_at_extra: values.stop_at_extra,
                    break_time_extra: values.break_time_extra,
                    work_time_extra: values.work_time_extra,
                    construction_id_extra: values.construction_id_extra,
                    work_detail_extra: values.work_detail_extra,
                    contractor_id_extra: values.contractor_id_extra?.map((item: any) => Number(item?.value)),
                    memo_extra: values.memo_extra,
                  },
                  resetForm
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_NIGHTTODAY:
                handleOnSubmit(
                  {
                    from: moment(dateRate.startDate).format("YYYY-MM-DD"),
                    to: moment(dateRate.endDate).format("YYYY-MM-DD"),
                    start_at: values.start_time,
                    stop_at: values.stop_time,
                    // overtime_reason_id: values.reasonType,
                    work_detail: values.work_detail,
                    title: values.title,
                    memo: values.memo,
                    type_of_overtime: changeToOfficial ? 1 : values.type_of_overtime,
                    break_time: values.break_time,
                    work_time: values.work_time,
                    construction_id: values.construction,
                    contractor_id: values.subContractor?.map((item: any) => Number(item?.value)),
                    start_at_extra: values.start_at_extra,
                    stop_at_extra: values.stop_at_extra,
                    break_time_extra: values.break_time_extra,
                    work_time_extra: values.work_time_extra,
                    construction_id_extra: values.construction_id_extra,
                    work_detail_extra: values.work_detail_extra,
                    contractor_id_extra: values.contractor_id_extra?.map((item: any) => Number(item?.value)),
                    memo_extra: values.memo_extra,
                  },
                  resetForm
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_HALFDAY:
                handleOnSubmit(
                  {
                    from: moment(dateRate.startDate).format("YYYY-MM-DD"),
                    to: moment(dateRate.endDate).format("YYYY-MM-DD"),
                    start_at: values.start_time,
                    stop_at: values.stop_time,
                    // overtime_reason_id: values.reasonType,
                    work_detail: values.work_detail,
                    title: values.title,
                    memo: values.memo,
                    type_of_overtime: changeToOfficial ? 1 : values.type_of_overtime,
                    break_time: values.break_time,
                    work_time: values.work_time,
                    construction_id: values.construction,
                    contractor_id: values.subContractor?.map((item: any) => Number(item?.value)),
                    overtime_reason_id: values.reasonType,
                    note: values.reasonContent ? values.reasonContent : null,
                    phone_number: values.phoneNumber,
                  },
                  resetForm
                );
                break;
            }
          }}
          validationSchema={schema}
        >
          {({ handleSubmit, values, handleChange, errors, touched, handleBlur, setFieldValue }) => {
            let componentToShow;
            setValuesTitle(values?.title);
            switch (values.title) {
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_VACATION:
                componentToShow = <OTNewVacation values={values} errors={errors} handleChange={handleChange} touched={touched} listReasons={listReasons} handleBlur={handleBlur} setFieldValue={setFieldValue} />;
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_HOLIDAY:
                componentToShow = (
                  <OTNewHoliday
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    touched={touched}
                    handleBlur={handleBlur}
                    optionSiteConstructions={optionSiteConstructions}
                    optionConstractors={optionConstractors}
                    setFieldValue={setFieldValue}
                    onChangeConstractors={onChangeConstractors}
                    onChangeConstruction={onChangeConstruction}
                  />
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_HALFDAY:
                componentToShow = (
                  <OTNewHalfDay
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    touched={touched}
                    handleBlur={handleBlur}
                    optionSiteConstructions={optionSiteConstructions}
                    optionConstractors={optionConstractors}
                    setFieldValue={setFieldValue}
                    onChangeConstractors={onChangeConstractors}
                    listReasons={listReasons}
                    onChangeConstruction={onChangeConstruction}
                  />
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_NIGHTSHIFT:
                componentToShow = (
                  <OTNewNightShift
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    touched={touched}
                    handleBlur={handleBlur}
                    optionSiteConstructions={optionSiteConstructions}
                    optionConstractors={optionConstractors}
                    setFieldValue={setFieldValue}
                    onChangeConstractors={onChangeConstractors}
                    onChangeConstruction={onChangeConstruction}
                  />
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_OVERTIME:
                componentToShow = (
                  <OTNewOvertime
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    touched={touched}
                    handleBlur={handleBlur}
                    optionSiteConstructions={optionSiteConstructions}
                    optionConstractors={optionConstractors}
                    setFieldValue={setFieldValue}
                    onChangeConstractors={onChangeConstractors}
                    onChangeConstruction={onChangeConstruction}
                  />
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_DAYTONIGHT:
                componentToShow = (
                  <OTNewDayToNight
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    touched={touched}
                    handleBlur={handleBlur}
                    optionSiteConstructions={optionSiteConstructions}
                    optionConstractors={optionConstractors}
                    setFieldValue={setFieldValue}
                    onChangeConstractors={onChangeConstractors}
                    onChangeConstractorsExtra={onChangeConstractorsExtra}
                    onChangeConstruction={onChangeConstruction}
                    onChangeConstructionExtra={onChangeConstructionExtra}
                  />
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_NIGHTTODAY:
                componentToShow = (
                  <OTNewNightToDay
                    values={values}
                    errors={errors}
                    handleChange={handleChange}
                    touched={touched}
                    handleBlur={handleBlur}
                    optionSiteConstructions={optionSiteConstructions}
                    optionConstractors={optionConstractors}
                    setFieldValue={setFieldValue}
                    onChangeConstractors={onChangeConstractors}
                    onChangeConstractorsExtra={onChangeConstractorsExtra}
                    onChangeConstruction={onChangeConstruction}
                    onChangeConstructionExtra={onChangeConstructionExtra}
                  />
                );
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_SPECIAL_LEAVE:
                componentToShow = <OTNewSpecialLeave values={values} errors={errors} handleChange={handleChange} touched={touched} listReasons={listReasons} handleBlur={handleBlur} setFieldValue={setFieldValue} />;
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_BEREAVEMENT:
                componentToShow = <OTNewBereavement values={values} errors={errors} handleChange={handleChange} touched={touched} handleBlur={handleBlur} />;
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_ABSENSE:
                componentToShow = <OTNewAbsense values={values} errors={errors} handleChange={handleChange} touched={touched} listReasons={listReasons} handleBlur={handleBlur} setFieldValue={setFieldValue} />;
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_TARDINESS:
                componentToShow = <OTNewTardiness values={values} errors={errors} handleChange={handleChange} touched={touched} listReasons={listReasons} handleBlur={handleBlur} setFieldValue={setFieldValue} />;
                break;
              case OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_EARLY_LEAVE:
                componentToShow = <OTNewEarlyLeave values={values} errors={errors} handleChange={handleChange} touched={touched} listReasons={listReasons} handleBlur={handleBlur} setFieldValue={setFieldValue} />;
                break;
              default:
                componentToShow = null;
                break;
            }
            return (
              <div className="wrapperContent">
                <div className="content">
                  {dataDetail?.confirmation?.length ? (
                    <div className="approval">
                      <OTApprovalProcess data={dataDetail} />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="newOT__user">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="userInfo">
                        <div className="userInfo__name">
                          <p className="title">氏名</p>
                          <p className="wrapperAvatar">
                            <Avatar
                              sx={{
                                height: "50px !important",
                                width: "50px !important",
                              }}
                              src={linkImage(userInfo?.avatar?.path)}
                            />
                            <span className="name">
                              {userInfo?.last_name}
                              {userInfo?.first_name}
                            </span>
                          </p>
                        </div>
                        {/* {enableCheckTypeOfOverTime &&
                                                    (checkTypeTextOT === 0 ? (
                                                        <div className="userInfo__typeTempo" onClick={() => submitChangeTypeOT(id)}>
                                                            本届に変更
                                                        </div>
                                                    ) : checkTypeTextOT === 1 ? (
                                                        <div className="userInfo__typeOfficial">本届</div>
                                                    ) : null)} */}
                      </div>
                      {!enableCheckTypeOfOverTime && (
                        <Grid className="label-type-overtime">
                          {/* <label className="label">シングルタイプ</label> */}
                          <div>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group-type_of_overtime"
                              name="controlled-radio-buttons-group-type_of_overtime"
                              value={values.type_of_overtime}
                              onChange={handleChange("type_of_overtime")}
                              className="form__checkbox"
                              style={{
                                display: "flex",
                                flexDirection: "column-reverse",
                                justifyContent: "center",
                              }}
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 28,
                                },
                                "& .MuiTypography-body1": {
                                  fontWeight: "700",
                                },
                                "& .MuiRadio-root": {
                                  display: "none",
                                },
                              }}
                            >
                              <FormControlLabel value={1} className={!enableCheckTypeOfOverTime ? clsx(parseInt(values.type_of_overtime) === 0 ? "active" : "") : "active"} label="本届" control={<Radio />} />
                              <FormControlLabel
                                value={0}
                                label="予定届"
                                control={<Radio />}
                                className={clsx(parseInt(values.type_of_overtime) === 1 ? "active" : "")}
                                sx={{
                                  "& .MuiTypography-root": {
                                    width: "43px",
                                  },
                                  "& .MuiTypography-body1": {
                                    fontWeight: "700",
                                  },
                                }}
                              />
                            </RadioGroup>
                          </div>
                        </Grid>
                      )}
                    </div>
                    {!isSectionChief && (
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <p className="label">所属</p>
                        {!!optionGroups?.length && (
                          <p className="label" style={{ paddingLeft: "4rem" }}>
                            {optionGroups[0]?.name}
                          </p>
                        )}
                      </Box>
                    )}

                    <Grid container spacing={2}>
                      <Grid item xs={12} md={7}>
                        <div className="user__title">
                          <p className="label">題名</p>
                          <AppSelect
                            options={overtimeNewTitle}
                            placeholder="タイムシートタイプを選択"
                            value={values.title}
                            handleChange={handleChange("title")}
                            name="title"
                            error={touched.title ? errors.title : undefined}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                      <Grid item xs={12} md={7}>
                        <div className="datePicker">
                          <AppDateRangePicker
                            value={{
                              startDate: dateRate?.startDate,
                              endDate: dateRate?.endDate,
                            }}
                            onChange={(e) => handleChangeDateRange(e)}
                            startText="開始"
                            endText="終了"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  {componentToShow}
                  {/* <div className="newOT__btn">
                    <div className="wrapperBtn">
                      <AppButton onClick={handleSubmit} text="申請" />
                    </div>
                  </div> */}

                    {enableCheckTypeOfOverTime && checkTypeTextOT === 0 ? (
                      <>
                      <div className="newOT__btn">
                        <div className="wrapperBtn">
                          <AppButton onClick={handleSubmit} text="予定届申請" />
                        </div>
                      </div>
                      <div className="newOT__btn">
                        <div className="wrapperBtn">
                          {/* <AppButton bgColor="#45b6fe" onClick={() => submitChangeTypeOT(id)} text="本届申請" /> */}
                          <AppButton bgColor="#45b6fe" onClick={() => {setChangeToOfficial(true); handleSubmit();}} text="本届申請" />
                        </div>
                      </div>
                      </>
                    ):(
                      <div className="newOT__btn">
                        <div className="wrapperBtn">
                          <AppButton onClick={handleSubmit} text="申請" />
                        </div>
                      </div>
                    )}
                  {id && (
                    <div className="newOT__btn">
                      <div className="delete">
                        <AppButton text="削除する" onClick={handleDelete} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    </StyledNewOT>
  );
};
