import { appRouteEnums } from "@enums";
import React from "react";
import { AppIconButton, AppModal, AppTopBar, CircularLoader } from "../Common";
import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    selectDocumentsLoading,
    selectTechnicalDocuments,
    fetchTechnicalDocuments,
    useAppSelector,
    readTechnicalDocument,
    deleteTechnicalDocument,
} from "@redux";
import { StyledTechnicalDocumentsComponent, TechnicalDocumentsItem } from ".";
import active_arrow_down from "@assets/Icons/active_arrow_down.svg";
import {
    DOCUMENT_EXCEL_FAILED_TO_LOAD,
    DOCUMENT_FAILED_TO_LOAD,
    LABEL_LIST_DOCUMENT,
    MESSAGE_DATA_NOT_FOUND,
    MESSAGE_LOADING_PDF,
} from "@configs";
//import { technicalDocumentsApi } from "src/Api/technicalDocuments";
import { StyledIframe, StyledPDF } from "../Document";
import { Document, Page } from "react-pdf";
import {
    converNumberToArrayOfInterger,
    isLinkExel,
    linkDocument,
    useWindowDimensions,
} from "@utils";
import { saveAs } from "file-saver";
import { TitleMenuComponent } from "../TitleMenuComponent";

export const TechnicalDocumentsView = () => {
    const technicalDocuments = useAppSelector(selectTechnicalDocuments);
    const loading = useAppSelector(selectDocumentsLoading);

    const [page, setPage] = React.useState<number>(1);
    const history = useHistory();
    const dispatch = useDispatch();
    const loadMore = false;
    const [openPDF, setOpenPDF] = React.useState<boolean>(false);
    const [linkPdf, setLinkPdf] = React.useState<any>("");
    const [numPages, setNumPages] = React.useState(null);
    const width = useWindowDimensions().width;

    const handleOnSelectTechnicalDocuments = async (path: any, title: any, id: any) => {
        try {
            dispatch(readTechnicalDocument(id));
            if (path.endsWith(".xlsx")) {
                const response = await fetch(path);
                const blob = await response.blob();
                saveAs(blob, `${title}.xlsx`);
            } else {
                handleOpenPDF(path);
            }
        } catch (error) {}
    };

    const handleDeleteTechnicalDocuments = (id: any) => {
        try {
            dispatch(deleteTechnicalDocument(id));
        } catch (err) {}
    };

    const handleOpenPDF = (value: any) => {
        if (width <= 980) {
            window.location.href = linkDocument(value);
        }
        setLinkPdf(value);
        setOpenPDF(true);
    };

    const handleClosePDF = () => {
        setOpenPDF(false);
        setLinkPdf("");
    };

    const handleFetchTechnicalDocuments = () => {
        try {
            dispatch(fetchTechnicalDocuments(page));
        } catch (err) {
            /** */
        }
    };

    const handleLoadMore = () => {
        setPage(page + 1);
    };
    React.useEffect(() => {
        handleFetchTechnicalDocuments();
        return () => {
            //    do something cleanup
        };
    }, [page]);
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };
    return (
        <StyledTechnicalDocumentsComponent>
            <AppTopBar />
            <CircularLoader loading={loading} />
            {width >= 981 && (
                <AppModal open={openPDF} handleClose={handleClosePDF}>
                    <StyledPDF>
                        {linkPdf ? (
                            isLinkExel(linkPdf) ? (
                                <p className="failePDF">{DOCUMENT_EXCEL_FAILED_TO_LOAD}</p>
                            ) : width >= 991 ? (
                                <StyledIframe
                                    heightCalculationMethod="lowestElement"
                                    src={linkDocument(linkPdf)}
                                    log
                                    scrolling={true}
                                    autoResize={false}
                                />
                            ) : (
                                <Document
                                    file={linkDocument(linkPdf)}
                                    options={{ workerSrc: "/pdf.worker.js" }}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    error={DOCUMENT_FAILED_TO_LOAD}
                                    loading={MESSAGE_LOADING_PDF}
                                >
                                    {numPages &&
                                        converNumberToArrayOfInterger(numPages).map(
                                            (page, index) => <Page pageNumber={index} />
                                        )}
                                </Document>
                            )
                        ) : (
                            <p className="failePDF">{DOCUMENT_FAILED_TO_LOAD}</p>
                        )}
                    </StyledPDF>
                </AppModal>
            )}
            <div className="wrapperForm-top">
                <p className="top-icon" onClick={() => history.push(appRouteEnums.PATH_HOME)}>
                    <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
                </p>
            </div>
            <div className="wrapperForm">
                <p className="top-icon">
                    <TitleMenuComponent text={LABEL_LIST_DOCUMENT} />
                </p>
            </div>
            <div className="noti">
                <div className="noti__wrapper">
                    <div className="noti__content">
                        {technicalDocuments?.length
                            ? technicalDocuments.map((noti, index) => (
                                  <div key={index}>
                                      <TechnicalDocumentsItem
                                          handleOnSelectTechnicalDocuments={() =>
                                              handleOnSelectTechnicalDocuments(
                                                  noti?.file.path,
                                                  noti?.title,
                                                  noti?.id
                                              )
                                          }
                                          data={noti}
                                          handleDeleteTechnicalDocuments={() => {
                                              handleDeleteTechnicalDocuments(noti?.id);
                                          }}
                                      />
                                  </div>
                              ))
                            : !loading && (
                                  <p className="messageNotFound">{MESSAGE_DATA_NOT_FOUND}</p>
                              )}
                    </div>
                </div>
            </div>
            {/* <div className="wrapperLoading">
                <CircularLoader loading={loading} size={15} type="fullContent" />
            </div> */}
            {!loading && loadMore && (
                <p className="viewMore">
                    <span className="viewMore__text" onClick={() => handleLoadMore()}>
                        続きを見る
                    </span>
                    <img
                        className="viewMore__icon"
                        src={active_arrow_down}
                        alt="icon"
                        onClick={() => handleLoadMore()}
                    />
                </p>
            )}
        </StyledTechnicalDocumentsComponent>
    );
};
