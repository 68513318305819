import axios from "axios";
const queryString = require("query-string");
import { store, resetAuth, updateSnackBar } from "@redux";
// config axios
const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_DEV_URL,
    headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
    },

    paramsSerializer: (params) => queryString.stringify(params),
});
axiosClient.interceptors.request.use((config) => {
    const token = store.getState().auth?.tokenInfoAuth;
    config.headers = {
        Authorization: `Bearer ${token}`,
    };

    return config;
});

axiosClient.interceptors.response.use(
    (response) => {
        if (response && response.data) {
            return response;
        }
        return response;
    },
    (error: any) => {
        if (error.response && error.response.status === 401) {
            store.dispatch(resetAuth());
            store.dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: error.response?.data?.message,
                })
            );
            // await authApi.logout();
        }
        return Promise.reject(error);
    }
);

export default axiosClient;
