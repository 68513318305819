import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";

import { StyledModalMenuComponent } from "./styles";
import { linkImage } from "@utils";
import { useAppSelector, selectMenu, getMenus, selectAuth, selectVehicle, /*selectGroupsLoading,*/ selectTimesheetsLoading, selectDocumentsLoading, /*selectEmployeesLoading*/ selectOvertimeLoading} from "@redux";
import { useHistory } from "react-router-dom";

export const MenuComponent = () => {
    const menus = useAppSelector(selectMenu);
    const { dataMenu } = menus;
    const auth = useAppSelector(selectAuth) || {tokenInfoAuth: false};
    const { tokenInfoAuth } = auth;
    const vehicleState = useAppSelector(selectVehicle);
    const vehiclesLoading = vehicleState.ongoingRequests?.length > 0;

    //const groupsLoading = useAppSelector(selectGroupsLoading);
    const timesheetsLoadingState = useAppSelector(selectTimesheetsLoading);
    const documentsLoading = useAppSelector(selectDocumentsLoading);
    const overtimesLoading = useAppSelector(selectOvertimeLoading);
    //const employeesLoading = useAppSelector(selectEmployeesLoading);
    
    //const timesheetsLoading = groupsLoading || timesheetsLoadingState || employeesLoading;
    
    // hooks
    const dispatch = useDispatch();
    const history = useHistory();

    // event choose item in menu
    const handleOnClick = (link: string) => {
        // window.location.href = link;
        history.push(link);
    };
    React.useEffect(() => {
        if (tokenInfoAuth) {
            dispatch(getMenus({ dispatch }));
        }
    }, [tokenInfoAuth, dispatch]);

    return (
        <StyledModalMenuComponent>
            {dataMenu ? (
                <div className="menu__wrapper">
                    {dataMenu ? (
                        dataMenu.map((menu: any) => {
                            const loading = (menu.id === 8 && vehiclesLoading) || (menu.id === 7 && timesheetsLoadingState) || (menu.id === 4 && documentsLoading) || (menu.id === 9 && overtimesLoading);
                            const linkUrl = new URL(menu.link);
                            const localLink = linkUrl.pathname + linkUrl.search + linkUrl.hash;
                            const spinner = loading ? (<CircularProgress size={"1em"} />) : null;
                            return (
                                <div
                                    key={menu.id}
                                    className="menu__item"
                                    onClick={() => handleOnClick(localLink)}
                                >
                                    <Box style={{ display: "flex", justifyContent: "center" }}>
                                        <div className="menu__wrapperImage">
                                            <img
                                                className="menu__image"
                                                src={linkImage(menu?.icon?.path)}
                                            />
                                        </div>
                                    </Box>
                                    <p className="menu__title">{menu.name} {spinner}</p>
                                </div>
                            );
                        })
                    ) : (
                        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                            <CircularProgress />
                        </Box>
                    )}
                </div>
            ) : (
                <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <CircularProgress />
                </Box>
            )}
        </StyledModalMenuComponent>
    );
};
